#app-sidebar-1 > div {
  background-color: #0e1315;
}

* {
  border-color: var(--gray-200);
}

.p-toast-top-center {
  z-index: 99999999 !important;
}

.step-title {
  text-transform: uppercase;
  color: #999999;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

//p-component,
//.p-component * {
//  font-size: 14px !important;
//}

.p-disabled,
.p-disabled * {
  cursor: not-allowed !important;
}

.p-scrollpanel .p-scrollpanel-bar {
  background: var(--primary-color);
  display: none;
}

.p-divider.p-divider-horizontal {
  //margin: 8px 0;
  //padding: 0 8px;
  margin: 24px 0;
}

.p-divider.p-divider-horizontal::before {
  border-top: 1px solid var(--surface-250);
}

.flat-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 24px 0;
  background: #FFFFFF;

  & .header {
    padding: 0 24px 24px;
    border-bottom: 1px solid #e0e0e0;
    color: #1a1a1a;
    font-weight: 600;
    font-size: 18px !important;
    line-height: 28px;
  }

  & .content {
    padding: 24px 24px 0 24px;
    //max-width: 862px;
  }
}

.flex-30 {
  // flex: 1 1 30%;
  flex: 1 1 0;
}

.flex-70 {
  //flex: 1 1 70%;
  flex: 2 1 0;
}

.p-avatar {
  color: #999999;
  background: #ededed;
}

.p-avatar.p-avatar-circle {
  border-radius: 50% !important;
}

.p-overlaypanel-content {
  padding: 16px;
}

.invalid-card {
  border-color: var(--error);
}

label {
  color: #4C4C4C;
  font-weight: 500;
}

.pac-container {
  z-index: 999999999 !important;
}

.border-error {
  border: 1px solid var(--error);
}

.disable-wrapper {
  background-color: #FAFAFA;
  cursor: not-allowed;

  & input,
  .p-dropdown {
    pointer-events: none;
    background-color: #FAFAFA;
    color: #0D0D0D;
  }

  & .p-element, div {
    pointer-events: none;
  }
}

.p-button:disabled,
.p-disabled,
.p-disabled * {
  pointer-events: auto !important;
  cursor: not-allowed !important;
}

.p-tabview-nav {
  align-items: baseline;
  padding-bottom: 8px;

  & li .p-tabview-nav-link {
    border: none;
  }
}


body {
  margin: 0;
}

i {
  color: var(--gray-250);

  &.hovered {
    color: var(--gray-600);
  }
}

.active-menu {
  color: red;
}

.cursor-help {
  cursor: help;
}

.profile-image img {
  border-radius: 50%;
}

/* Customizations to the designer theme should be defined here */
/* It's on the end of this file so it can override default styles */
@import "extensions_buttons";
@import "extensions_inputs";
@import "extensions_typography";
@import "extensions_table";
@import "extensions_toast";
@import "extensions_sidebar";
@import "extensions_badge";
@import "primeicons/primeicons.css";
@import "variables";
