.p-sidebar {
  color: #1A1A1A;

  &.left-rounded {
    border-bottom-left-radius: 24px;
    border-top-left-radius: 24px;
  }

  &.right-rounded {
    border-bottom-right-radius: 24px;
    border-top-right-radius: 24px;
  }
}

.p-sidebar-header {
  padding: 24px;
}

.p-sidebar-content {
  border-top: 1px solid #E0E0E0;
  padding: 24px;
}
