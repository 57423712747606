/* Customizations to the designer theme should be defined here */

.p-button {
  border-radius: 8px !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.p-button-primary {
  color: var(--surface-a);
  background-color: var(--primary-color);

  &:hover {
    background-color: var(--primary-color-dark);
  }
}

.p-button-secondary {
  color: var(--surface-a);
  background-color: var(--secondary-color);

  &:hover {
    background-color: var(--secondary-darker-color);
  }

  &.gray {
    border: 1px solid #e0e0e0;
    color: #333333;
    background-color: #ffffff;

    &:hover {
      background-color: #f7f7f7;
    }
  }
}

.p-button-text {
  color: var(--brand-700);
  background-color: inherit;
  border: 0;

  &:hover {
    color: var(--gray-600);
    background-color: inherit;
  }
}

.dialog-button {
  background: #189c4d;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  &:hover {
    background: #127439;
  }
}

.p-checkbox .p-checkbox-box {
  border-radius: 4px;
  border: 1px solid #333333;
  width: 16px;
  height: 16px;

  &.p-highlight {
    background: #333333;

    & .p-checkbox-icon {
      color: #ffffff;
    }
  }
}

.p-checkbox-label {
  color: #4c4c4c;
  font-weight: 500;
  margin-bottom: 2px;
}

.p-radiobutton-box.p-highlight {
  background: #333333;

  & .p-radiobutton-icon {
    background-color: #ffffff;
    width: 6px;
    height: 6px;
  }
}

.filters-button {
  button {
    height: 35px !important;
    margin-top: 6px;
  }
}
