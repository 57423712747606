/* Customizations to the designer theme should be defined here */

.text-sm-medium {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #808080;

  &.bolder {
    color: #4C4C4C;
  }
}

.text-md {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1A1A1A;
}

.dialog-subheader {
  color: #1a1a1a;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 24px;
}