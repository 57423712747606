/* Customizations to the designer theme should be defined here */

.p-component,
.p-inputwrapper,
.p-password-mask {
  border-radius: 8px !important;

  &.ng-invalid.ng-touched,
  .ng-invalid.ng-dirty {
    border: 1px solid var(--error);
  }
}

.text-base {
  font-weight: 400;
  line-height: 24px;
}

.p-inputtext.ng-dirty.ng-invalid {
  color: var(--red-400)
}

.p-password-mask.ng-invalid.ng-touched input,
.p-password-mask.ng-invalid.ng-dirty input, {
  border: 0;
}

.dropdown-next-to {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-left: 0;

  &:focus {
    background: #FCFCEF;
    box-shadow: none;
  }
}

.input-next-to {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;

  &.p-focus {
    box-shadow: none !important;
  }
}

.dropdown-input-button {
  & .group-dropdown {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-right: 0;

    &:focus {
      background: #FCFCEF;
      box-shadow: none;
    }
  }

  & .group-input {
    border-radius: 0 !important;

    &.p-focus {
      box-shadow: none !important;
    }
  }

  & .group-button {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-left: 0;
    line-height: initial !important;

    &:focus {
      background: #FCFCEF;
      box-shadow: none;
    }
  }
}

.input-button {

  & .group-input {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;

    &.p-focus {
      box-shadow: none !important;
    }
  }

  & .group-button {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-left: 0;
    line-height: initial !important;

    &:focus {
      background: #FCFCEF;
      box-shadow: none;
    }
  }
}

.p-inputtext,
.p-password-input,
.p-dropdown-item {
  font-size: 14px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  border-radius: 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: #e9ecef;
  color: #495057;
  border-radius: 0;
}

.p-overlay {
  z-index: 999999 !important;
}

.p-calendar {
  width: 100%;

  //& input {
  //  border-top-right-radius: 0 !important;
  //  border-bottom-right-radius: 0 !important;
  //}

  & .p-datepicker-trigger {
    background: #333333;
    border-left: 0;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    color: white;
  }
}

.p-datepicker {
  z-index: 999999 !important;

  & .p-highlight {
    background-color: var(--secondary-color);
    color: var(--surface-a);
  }
}

.p-selectbutton {
  & div:first-child {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  & div:last-child {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.p-selectbutton .p-button.p-highlight {
  background-color: var(--secondary-color);
  color: var(--surface-a);
}
