.p-datatable-table {
  & th {
    background: var(--gray-50);
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    padding: 12px 24px;
    border-top: 1px solid var(--gray-200);
    color: var(--gray-600);

    &:first-child {
      border-top-left-radius: 9px;
      border-left: 1px solid var(--gray-200);
    }

    &:last-child {
      border-top-right-radius: 9px;
      text-align: right;
      border-right: 1px solid var(--gray-200);
    }
  }

  tr {
    padding: 24px;
    font-weight: 400;
    font-size: 14px;

    &:last-child {
      & td:first-child {
        border-bottom-left-radius: 9px;
      }
      & td:last-child {
        border-bottom-right-radius: 9px;
      }
    }

    & td {
      color: var(--gray-600);

      &:first-child {
        border-left: 1px solid var(--gray-200);
      }
      &:last-child {
        border-right: 1px solid var(--gray-200);
        text-align: right;
      }
    }
  }
}

.p-datatable .p-datatable-tbody > tr:focus {
  outline: none;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #e9ecef;
}

.p-paginator {
  justify-content: end !important;
  padding: 0;
  border: 0;
  margin: 0;

  & .p-dropdown {
    height: initial;
  }

  & .p-paginator-current {
    color: var(--grayscale-500, #999792);
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  & .p-paginator-page,
  .p-paginator-element {
    min-width: 24px !important;
    height: 24px !important;
    color: var(--grayscale-900, #332f25) !important;
  }

  & .p-paginator-pages .p-paginator-page.p-highlight {
    background: var(--primary-900, #8e9574);
    color: var(--white, #fff) !important;
  }
}

.p-overlaypanel-content {
  padding: 8px;

  & .action-wrapper {
    & .p-button-text {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #4c4c4c;

      &:hover {
        color: #333333;
        background: #f2f2f2;
      }
    }

    & .p-button-icon {
      color: #808080;
    }
  }
}

.table-filter {
  & .search-bar {
    margin-top: -32px;
  }
}

.table-num {
  background-color: #EDEDED;
  border-radius: 16px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4C4C4C;
}

//.p-datatable .p-datatable-tbody > tr > td {
//  border: none;
//}
//
//.p-datatable .p-datatable-tbody > tr {
//  border-bottom: 1px solid var(--gray-200);
//}

.p-datatable-thead th {
  text-transform: uppercase;
  color: #999999;
  font-weight: 00;
  font-size: 12px !important;
  line-height: 18px;
  letter-spacing: 0.05em;
}

.table-empty-box {
  background: var(--surface-50);
  width: 100%;
  min-height: 50vh;
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;

  & .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: var(--text-color);
  }

  & .desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--gray-600);
  }
}