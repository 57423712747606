.table-badge {
  border: 1px solid;
  border-radius: 16px;
  padding: 2px 8px 2px 6px;
  gap: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 8rem;
  line-height: 20px;

  & span {
    font-size: 12px !important;
  }
}

.dropdown-badge {
  border: 1px solid;
  border-radius: 16px;
  padding: 2px 8px 2px 6px;
  gap: 6px;
  display: flex;
  justify-content: start;
  align-items: center;
  line-height: 20px;

  & span {
    font-size: 12px !important;
  }
}

.dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

/* Badge types */

.is-active {
  &-true {
    background: #EDFAF3;
    color: #127439;
    border-color: #AEE9C6;

    & > .dot {
      background: #1EC360;
    }
  }

  &-false {
    background: #FEF3F2;
    color: #B42318;
    border-color: #FECDCA;

    & > .dot {
      background: #F04438;
    }
  }
}

.assistance {
  // NEW
  &-1 {
    border-color: #AED0E9;
    background: #EDF5FA;
    color: #124B74;

    & > .dot {
      background: #1E7EC3;
    }
  }

  // CANCELED
  &-2 {
    border-color: #E0E0E0;
    background: #F7F7F7;
    color: #4C4C4C;

    & > .dot {
      background: #808080;
    }
  }

  // FINISHED
  &-3 {
    border-color: #AEE9C6;
    background: #EDFAF3;
    color: #127439;

    & > .dot {
      background: #1EC360;
    }
  }

  // IN_PROGRTESS
  &-4 {
    border-color: #F8D8B2;
    background: #FDF6EE;
    color: #8C5718;

    & > .dot {
      background: #EB9229;
    }
  }

  // REQUEST
  &-5 {
    border-color: #E7A3A3;
    background: #F9E8E8;
    color: #A51616;

    & > .dot {
      background: #C83131;
    }
  }

  // DRAFT
  &-6 {
    border-color: #AED0E9;
    background: #EDF5FA;
    color: #124B74;

    & > .dot {
      background: #1E7EC3;
    }
  }
}

.payment-status {
  // NA CEKANJU,
  &-2 {
    border-color: #F1F3B4;
    background: #FCFCEF;
    color: #81841C;

    & > .dot {
      background: #D9DD2F;
    }
  }

  // U OBRADI
  &-1 {
    border-color: #AEE6E9;
    background: #EDFAFA;
    color: #126E74;

    & > .dot {
      background: #1EB9C3;
    }
  }

  // GOTOVO
  &-3 {
    border-color: #AEE9C6;
    background: #EDFAF3;
    color: #127439;

    & > .dot {
      background: #1EC360;
    }
  }

  // POVRACAJ NOVCA I OTKAZANO
  &-4,
  &-5 {
    border-color: #FECDCA;
    background: #FEF3F2;
    color: #B42318;

    & > .dot {
      background: #F04438;
    }
  }
}