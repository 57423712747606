// Theme Specific Variables
$primaryColor: #c21a1a;
$primaryDarkColor: #A51616;
$primaryDarkerColor: #1976D2;
$primaryTextColor: #1A1A1A;
$secondaryColor: #333333;
$secondaryDarkerColor: #1A1A1A;

$colors: (
        "blue": #2196F3,
        "green": #4caf50,
        "yellow": #FBC02D,
        "cyan": #00BCD4,
        "pink": #E91E63,
        "indigo": #3F51B5,
        "teal": #009688,
        "orange": #F57C00,
        "bluegray": #607D8B,
        "purple": #9C27B0,
        "red": #F04438,
        "primary": $primaryColor
);

$white: #FFFFFF;
$gray600: #666666;
$gray50: #F7F7F7;
$gray200: #E0E0E0;
$red500: #F04438;

$borderRadius: 0.563rem;
$inputListItemBorderRadius: 2rem;

// Mandatory Designer Variables
@import './variables/general';
@import './variables/form';
@import './variables/button';
@import './variables/panel';
@import './variables/_data';
@import './variables/_overlay';
@import './variables/_message';
@import './variables/_menu';
@import './variables/_media';
@import './variables/_misc';

:root {
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --surface-a: #ffffff;
  --surface-b: #f8f9fa;
  --surface-c: #e9ecef;
  --surface-d: #dee2e6;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --text-color: #{$primaryTextColor};
  --text-color-secondary: #6c757d;
  --primary-color: #{$primaryColor};
  --primary-color-dark: #{$primaryDarkColor};
  --primary-color-text: #{$primaryTextColor};
  --surface-0: #ffffff;
  --surface-50: #FAFAFA;
  --surface-100: #F5F5F5;
  --surface-200: #EEEEEE;
  --surface-250: #EDEDED;
  --surface-300: #E0E0E0;
  --surface-400: #BDBDBD;
  --surface-500: #9E9E9E;
  --surface-600: #757575;
  --surface-700: #616161;
  --surface-800: #424242;
  --surface-900: #212121;
  --opacity-100:rgba(0, 0, 0, 0.8);
  --opacity-150: rgba(0, 0, 0, 0.5);
  --surface-hover: #F0F0F0;
  --surface-border: #C8C8C8;
  --gray-100: #808080;
  --gray-300: #C7C7C7;
  --gray-250: #999999;
  --gray-150: #4c4c4c;
  --blue-450: #124B74;
  --blue-500: #1E7EC3;
  --blue-550: #EDF5FA;
  --blue-600: #AED0E9;
  --green-100: rgb(18, 116, 57);
  --green-200: rgb(237, 250, 243);
  --green-150: rgb(30, 195, 96);
  --green-250: #1EC360;
  --content-padding: #{$panelContentPadding};
  --inline-spacing: #{$inlineSpacing};
  --border-radius: 8px;
  --surface-ground: #f8f9fa;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #dee2e6;
  --surface-hover: #e9ecef;
  --danger-50: #fecdca;
  --danger-70: #fce8e8;
  --danger-100: #b42318;
  --danger-150: #EB9229;
  --green-400: #28a745;
  --green-450: #AEE9C6;
  --green-500: #edfaf3;
  --green-550: #127439;
  --warning-50: #4C4C4C;
  --maskbg: #{$maskBg};
  --highlight-bg: #{$highlightBg};
  --highlight-text-color: #{$highlightTextColor};
  --focus-ring: #{$focusShadow};
  --brand-700: #A51616;
  color-scheme: light;
  --error: #{$red500};
  --red-500: #{$red500};
  scale-sm: 40px;
  --gray-50: #{$gray50};
  --gray-600: #{$gray600};
  --gray-200: #{$gray200};
  --secondary-color: #{$secondaryColor};
  --secondary-darker-color: #{$secondaryDarkerColor};
}
