@import './variables';
@import './_fonts';
@import '../../theme-base/_components';
@import './_extensions';


.center-div{
  width: 50%;
  margin-left: 25%;
}
