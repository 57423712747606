.p-toast .p-toast-message {

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  color: #ffffff;
  padding: 12px 16px 12px 16px;
  border-radius: 12px;

  & .p-toast-message-icon {
    color: #ffffff;
  }
  & .p-toast-icon-close {
    color: #ffffff;
    & svg {
      width: 12px;
    }
  }

  & .p-toast-message-content {
    padding: 0;
    width: 100%;
    align-items: center;

    & .pi,
    .p-toast-message-text{
      font-weight: 700;
    }

    & .p-toast-message-icon {
      font-size: 18px !important;
      margin-top: 3px;
    }
  }

  & .p-toast-summary {
    display: none;
  }


  &.p-toast-message-success {
    background-color: #189C4D;
  }

  &.p-toast-message-warn {
    background-color: #EB9229;
    color: #ffffff;
  }

}

.p-toast-detail  {
  margin: 0;
}